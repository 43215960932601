<template>
  <div
    :class="$store.state.media.isDesktop == true ? 'generateCSR-Pc' : 'generateCSR-notPc'"
  >
    <div class="titleBox">
      <div class="title">SSL证书格式转换工具</div>
      <div class="typeTips">将您的SSL证书转换为不同的格式</div>
    </div>
    <div class="content">
      <div class="content-title">选择文件和转换参数：</div>
      <div class="select">
        <div class="select-item">
          <div class="label">源文件格式*</div>
          <div class="radio">
            <RadioGroup v-model="toolData.format">
              <Radio label="pem">PEM</Radio>
              <Radio label="jks">JKS</Radio>
              <Radio label="pkcs12">PKCS12</Radio>
            </RadioGroup>
          </div>
        </div>
        <div class="select-item">
          <div class="label">转换格式*</div>
          <div class="radio">
            <RadioGroup v-model="toolData.transfer">
              <Radio v-if="toolData.format != 'pem'" label="pem">PEM</Radio>
              <Radio v-if="toolData.format != 'jks'" label="jks">JKS</Radio>
              <Radio v-if="toolData.format != 'pkcs12'" label="pkcs12">PKCS12</Radio>
            </RadioGroup>
          </div>
        </div>
        <div
          class="select-item"
          v-if="toolData.format == 'jks' || toolData.transfer == 'jks'"
        >
          <div class="label">JDK版本*</div>
          <div class="radio">
            <RadioGroup v-model="toolData.jdkVersion">
              <Radio label="jdk1.8"></Radio>
              <Radio label="jdk1.7"></Radio>
              <Radio label="jdk1.6"></Radio>
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
    <div class="contentBox" style="width: 100%" v-if="toolData.transfer != ''">
      <input type="file" ref="fileInput" @change="fileChange" style="display: none" />
      <div class="content">
        <div class="content-title">证书文件:</div>
        <div class="upload">
          <Button type="primary" @click="handleClick('cert')" icon="md-cloud-upload"
            >上传文件</Button
          >

          <div class="fileName">{{ certFileName }}</div>
        </div>
      </div>
      <div class="content" v-if="toolData.format == 'pem'">
        <div class="content-title">私钥文件:</div>
        <div class="upload">
          <Button type="primary" @click="handleClick('key')" icon="md-cloud-upload"
            >上传文件</Button
          >
          <div class="fileName">{{ keyFileName }}</div>
        </div>
      </div>
      <div class="content" v-if="toolData.format != 'pem'">
        <div class="content-title">原文件密码:</div>
        <Input
          v-model="toolData.oldpass"
          type="password"
          placeholder="请输入文件密码"
          suffix="ios-keypad"
          style="width: 300px"
        />
      </div>
      <div class="content" v-if="toolData.format == 'pem'">
        <div class="content-title">私钥密码:</div>
        <Input
          v-model="toolData.keypass"
          type="password"
          placeholder="请输入文件密码"
          suffix="ios-keypad"
          style="width: 300px"
        />
      </div>
      <div
        class="content"
        v-if="
          toolData.format == 'pem' ||
          toolData.transfer == 'pkcs12' ||
          toolData.transfer == 'jks'
        "
      >
        <div class="content-title">新文件密码:</div>
        <Input
          v-model="toolData.password"
          type="password"
          placeholder="请输入文件密码"
          suffix="ios-keypad"
          style="width: 300px"
        />
      </div>
      <div class="submitBox">
        <Button type="primary" :loading="loading" @click="submit">转换证书格式</Button>
        <div class="errorTips" v-if="errorMsg != ''">错误提示：{{ errorMsg }}</div>
      </div>
    </div>

    <div class="tips">
      <div class="tips-title">SSL证书格式转换常见问题</div>
      <div class="tips-content">
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>常见的SSL证书格式有哪些，适用于哪类服务器？ </strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                <strong>DER：</strong>
                二进制编码的证书格式，相当于PEM格式的二进制版本，证书后缀有：.DER .CER
                .CRT，主要用于Java平台。
              </li>
              <li>
                <strong>PEM：</strong>
                Base64编码的证书格式，是将二进制版本Base64编码后，以” -----BEGIN
                CERTIFICATE -----”开头, “-----END CERTIFICATE-----”结尾。证书后缀有： .PEM
                .CER .CRT，主要用于Apache和Nginx服务器。
              </li>
              <li>
                <strong>PKCS#7：</strong>
                Base64编码的证书格式，是将二进制版本Base64编码后，以” -----BEGIN
                CERTIFICATE -----”开头, “-----END CERTIFICATE-----”结尾。证书后缀有： .PEM
                .CER .CRT，主要用于Apache和Nginx服务器。
              </li>
              <li>
                <strong>PKCS#12：</strong>
                PKCS标准中的PKCS#12（Personal Information Exchange Syntax
                Standard）。它包含私钥，证书链，用户证书，并设置了密码。证书后缀有: .P12
                .PFX，主要用于Windows Server（IIS）。
              </li>
              <li>
                <strong>JKS：</strong>
                JavaKeyStore的缩写，它包含私钥，证书链，用户证书，并设置了密码。证书后缀是.jks。主要用于Tomcat服务器。
              </li>
            </ul>
          </div>
        </div>
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>获取的SSL证书一般是哪种证书格式？ </strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                一般而言，CA签发的SSL证书会发送到用户邮箱中，多为.pem或.crt的证书格式。如果用户服务器无法使用该证书格式，
                image.png则需要使用证书转换工具转化SSL证书格式。不过，在锐成平台购买的SSL证书，可自行下载所需格式证书直接部署到相应服务器上。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineExpose, watch, onMounted } from "vue";
import { ssLtransferApi } from "@/api/tools";
import { Message } from "view-ui-plus";
const reader = new FileReader();
const toolData = reactive({
  format: "pem", // 当前格式
  transfer: "", // 转换目标格式
  jdkVersion: "", // JDK版本
  certFile: null, // 证书文件
  keyFile: null, // 私钥文件
  oldpass: "", // 原文件密码
  keypass: "", // 私钥密码
  password: "", // 新文件密码
});
let fileType = ref("");
let certFileName = ref("请上传证书");
let keyFileName = ref("请上传私钥文件");
let fileInput = ref(null);
let errorMsg = ref("");
let loading = ref(false);
// 上传点击按钮
const handleClick = (type) => {
  fileInput.value.click(); // 触发隐藏的文件输入框
  fileType.value = type;
};

const fileChange = (event) => {
  if (fileType.value == "cert") {
    toolData.certFile = event.target.files[0];
    certFileName.value = event.target.files[0].name;
  } else {
    toolData.keyFile = event.target.files[0];
    keyFileName.value = event.target.files[0].name;
  }
};
const submit = () => {
  errorMsg.value = [];

  const requiredFields = {
    format: true,
    transfer: true,
    jdkVersion: true,
  };
  if (toolData.format === "pem") {
    requiredFields.certFile = true; // 证书文件
    requiredFields.keyFile = true; // 私钥文件
    requiredFields.keypass = true; // 私钥密码
  } else if (["jks", "pkcs12"].includes(toolData.format)) {
    requiredFields.certFile = true; // 证书文件
    requiredFields.oldpass = true; // 原文件密码
  }

  if (["jks", "pkcs12"].includes(toolData.transfer)) {
    requiredFields.password = true; // 新文件密码
  }

  const validationChecks = Object.keys(requiredFields).map((field) => ({
    field,
    message: `请添加${getFieldDescription(field)}`,
  }));

  for (const check of validationChecks) {
    if (!toolData[check.field]) {
      errorMsg.value = check.message;
      break;
    }
  }
  if (errorMsg.value != "") {
    console.log("错误信息:", errorMsg.value);
  } else {
    loading.value = true;
    const payload = {};
    for (const field in requiredFields) {
      if (requiredFields.hasOwnProperty(field)) {
        payload[field] = toolData[field];
      }
    }
    ssLtransferApi(payload)
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          window.location.href = `https://api.rjxssl.com/api/download?path=${res.data.path}`;
          Message.success("转化成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        loading.value = false;
        errorMsg.value = err.msg;
      });
  }
};
// 获取字段描述
const getFieldDescription = (field) => {
  switch (field) {
    case "format":
      return "当前格式";
    case "transfer":
      return "转换目标格式";
    case "jdkVersion":
      return "JDK版本";
    case "certFile":
      return "证书文件";
    case "keyFile":
      return "私钥文件";
    case "oldpass":
      return "原文件密码";
    case "keypass":
      return "私钥密码";
    case "password":
      return "新文件密码";
    default:
      return field;
  }
};

// 监听toolData.format的变化
watch(
  () => toolData.format,
  (newVal, oldVal) => {
    if (newVal) {
      toolData.transfer = ""; // 清除转换格式
      toolData.oldpass = ""; // 清除原文件密码
      toolData.keypass = ""; // 清除私钥密码
      toolData.password = ""; // 清除新文件密码
      certFileName.value = "请上传证书"; // 清除证书文件名
      keyFileName.value = "请上传私钥文件"; // 清除私钥文件名
      toolData.certFile = null; // 清除证书文件对象
      toolData.keyFile = null; // 清除私钥文件对象
    }
  }
);
onMounted(() => {});
</script>
<style lang="less" scoped>
.generateCSR-Pc {
  padding: 25px 5% 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .titleBox {
    margin-bottom: 15px;
    .title {
      font-size: 36px;
      color: #000;
    }
    .typeTips {
      text-align: center;
      span {
        color: #1374e0;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 15px 25px;
    .content-title {
      font-family: PingFang-SC-Medium;
      font-size: 18px;
      margin-bottom: 10px;
      color: #111111;
    }
    .select {
      .select-item {
        height: 40px;
        line-height: 40px;
        background-color: #f4f5f9;
        display: flex;

        .label {
          border-right: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          padding: 0 70px;
          text-align: c;
          width: 40%;
        }
        .radio {
          width: 100%;
          padding: 0 100px;
          // border-right: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
        }
      }
    }
    .upload {
      padding: 20px 0;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .fileName {
        margin-left: 30px;
      }
    }
  }
  .submitBox {
    text-align: center;
    margin-top: 35px;
  }
  :deep(.ivu-form-item-label) {
    text-align-last: left !important;
  }
  :deep(.ivu-form-item-content) {
    margin-left: 0px !important;
  }
  .result {
    width: 100%;
    .result-title {
      font-size: 18px;
    }
    .result-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .certType {
        .type_name {
          font-size: 20px;
          color: #000;
        }
      }
    }
    .buildPfx {
      width: 100%;
      height: 450px;
      overflow: auto;
      background-color: #f4f5f9;
      padding: 14px 10px;
      border-radius: 5px;
    }
  }
  .tips {
    width: 100%;
    padding: 40px 25px;
    margin-top: 30px;
    background-color: #f4f5f9;
    .tips-title {
      font-size: 18px;
      color: #1374e0;
      margin-bottom: 25px;
    }
    .tips-content {
      .question-item {
        display: flex;
        .question {
          min-width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: solid 1px #cccccc;
          color: #1374e0;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        .answer {
          min-width: 20px;
          height: 20px;
          background-color: #1374e0;
          color: #ffffff;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        ul {
          list-style-type: none;
        }
      }
    }
  }
}
.generateCSR-notPc {
  padding: 25px 5% 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .titleBox {
    margin-bottom: 15px;
    .title {
      font-size: 36px;
      color: #000;
    }
    .typeTips {
      text-align: center;
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 15px 0;
    .content-title {
      font-family: PingFang-SC-Medium;
      font-size: 18px;
      color: #111111;
      margin-bottom: 10px;
    }
    .select {
      .select-item {
        height: 40px;
        line-height: 40px;
        background-color: #f4f5f9;
        display: flex;

        .label {
          border-right: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          padding: 0 20px;
          text-align: center;
          width: 40%;
        }
        .radio {
          width: 100%;
          padding: 0 20px;
          // border-right: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
        }
      }
    }
  }
  .result {
    width: 100%;
    .buildPfx {
      // width: 100%;
      background-color: #f5f5f5;
      border: 1px solid #cecece;
      padding: 14px 10px;
      border-radius: 5px;
      overflow-wrap: break-word;
      height: 400px;
      overflow: scroll;
    }
  }
  .tips {
    width: 100%;
    padding: 40px 25px;
    margin-top: 30px;
    background-color: #f4f5f9;
    .tips-title {
      font-size: 18px;
      color: #1374e0;
      margin-bottom: 25px;
    }
    .tips-content {
      .question-item {
        display: flex;
        margin-bottom: 15px;
        .question {
          min-width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: solid 1px #cccccc;
          color: #1374e0;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        .answer {
          min-width: 20px;
          height: 20px;
          background-color: #1374e0;
          color: #ffffff;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        ul {
          list-style-type: none;
        }
      }
    }
  }
}
</style>
