// 证书订单详情
<template>
  <div
    class="certDetail"
    :class="$store.state.media.isDesktop == true ? 'certDetail-Pc' : 'certDetail-notPc'"
  >
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/user" class="breadItem"> 个人中心 </BreadcrumbItem>
        <BreadcrumbItem key="2" to="/cert" class="breadItem"> 我的证书 </BreadcrumbItem>
        <BreadcrumbItem key="3" to="/certDetail" class="breadItem">
          证书订单详情
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="process">
      <div class="section-hd">状态：{{ certData.cert.status_text }}</div>
      <div class="section-bd tab-box">
        <Tabs v-model="activeTabs" @on-click="tabsClick">
          <TabPane label="基本信息" name="1">
            <div class="processDetail">
              <div class="detail-title">基本信息</div>
              <div class="detail-content">
                <div class="content-item">
                  <div class="item-label">CA订单编号：</div>
                  <div>{{ certData.cert.ca_id }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">订单编号：</div>
                  <div>{{ certData.order_id }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">产品名称：</div>
                  <div>{{ certData.product.store_name }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">附加域名：</div>
                  <div>{{ certData.san }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">购买时长：</div>
                  <div>{{ certData.periods_text }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">订单价格：</div>
                  <div>
                    <span class="order_price">{{ certData.pay_price }}元</span>
                  </div>
                  <Button
                    style="margin-left: 15px"
                    type="error"
                    v-if="certData.refund_status == 0"
                    @click="applyRefund(certData.order_id)"
                    >申请退款</Button
                  >
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="申请信息" name="2">
            <div class="processDetail">
              <div class="detail-title">申请信息</div>
              <div class="detail-content">
                <div class="applyForm">
                  <Form
                    ref="certDataForm"
                    :model="formItem"
                    :rules="ruleItem"
                    label-position="left"
                    :label-width="85"
                  >
                    <FormItem
                      label="主域名"
                      :prop="certData.product.support_san == 0 ? 'common_name' : 'san'"
                    >
                      <Input
                        v-model="formItem.all_domain"
                        v-if="certData.product.support_san == 0"
                        :placeholder="certData.placeholder.common_effect"
                      ></Input>
                      <Input
                        v-model="formItem.all_domain"
                        v-if="certData.product.support_san == 1"
                        type="textarea"
                        :autosize="{ minRows: 10, maxRows: 15 }"
                        :placeholder="certData.placeholder.san_effect"
                      ></Input>
                    </FormItem>
                    <FormItem label="邮箱" prop="email">
                      <Input
                        v-model="formItem.email"
                        placeholder="请输入联系人 Email"
                      ></Input>
                    </FormItem>
                    <FormItem
                      label="公司"
                      prop="company_id"
                      v-if="
                        certData.product.ssl_type == 2 ||
                        certData.product.validation_level == 1 ||
                        certData.product.validation_level == 2
                      "
                    >
                      <Select
                        v-model="formItem.company_id"
                        placeholder="请选择公司"
                        :clearable="true"
                      >
                        <Option
                          v-for="item in companyList"
                          :value="item.id"
                          :key="item.id"
                          >{{ item.organization }}</Option
                        >
                      </Select>
                      <div class="tips">
                        无公司信息？<a @click="showCompanyDrwaer">点我添加</a>
                      </div>
                    </FormItem>
                    <!-- OV EV 代码签名 需要公司信息 -->
                    <FormItem label="Csr：" prop="csr_type">
                      <div class="csr">
                        <Select
                          v-model="formItem.csr_type"
                          style="width: 150px"
                          placeholder="请选择"
                        >
                          <Option value="1" label="自动生成"> </Option>
                          <Option value="0" label="粘贴CSR"> </Option>
                        </Select>
                        <div class="apply">
                          <Select
                            v-model="formItem.cert_type"
                            style="width: 150px"
                            placeholder="请选择"
                            v-show="formItem.csr_type != 0"
                          >
                            <Option value="0" label="RSA"> </Option>
                            <Option value="3" label="ECDSA"> </Option>
                          </Select>
                          <Select
                            v-model="formItem.hash_algorithms"
                            v-show="formItem.csr_type != 0"
                            style="width: 150px"
                            placeholder="请选择"
                          >
                            <Option value="sha256" label="SHA256"> </Option>
                            <Option value="sha384" label="SHA384"> </Option>
                            <Option value="sha512" label="SHA512"> </Option>
                          </Select>
                          <Select
                            v-model="formItem.keysize"
                            style="width: 150px"
                            v-show="formItem.csr_type != 0"
                            placeholder="请选择"
                          >
                            <Option value="2048" label="2048"> </Option>
                            <Option value="3072" label="3072"> </Option>
                            <Option value="4096" label="4096"> </Option>
                          </Select>
                        </div>
                        <Input
                          v-show="formItem.csr_type == '0'"
                          v-model="formItem.csr"
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 15 }"
                          placeholder="请在这里粘贴你的CSR"
                        ></Input>
                        <Button
                          type="primary"
                          v-if="certData.cert.status == 0"
                          class="applyBtn"
                          @click="applyCert"
                          >申请</Button
                        >
                        <div
                          class="btnBox"
                          v-if="
                            certData.cert.status == 1 &&
                            certData.product.support_san == 1 &&
                            certData.cert.place_order_data.san.length > 0
                          "
                        >
                          <Button
                            class="apply"
                            type="primary"
                            @click="showDeleteDomainModal = true"
                            >删除域名</Button
                          >
                          可以删除证书dcv验证未通过域名
                        </div>
                        <div class="btnBox" v-if="certData.cert.status == 2">
                          <Button
                            class="apply"
                            :loading="reissueLoading"
                            type="primary"
                            @click="certSREsueSubmit"
                            >重新颁发</Button
                          >
                          当前证书将在一周内吊销
                        </div>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="审核信息" name="3">
            <div class="processDetail" v-if="certData.cert.status != 1">
              <div class="detail-title">审核信息</div>
              <div class="detail-content">
                <div class="content-tip">证书状态非审核中</div>
              </div>
            </div>
            <div class="processDetail" v-if="certData.cert.status == 1">
              <div class="detail-title">审核信息</div>
              <div class="detail-top" v-if="certData.product.ssl_type != 2">
                <ButtonGroup>
                  <Button type="primary" :loading="noticeLoading" @click="noticeCACheck"
                    >重新验证</Button
                  >
                  <Dropdown
                    @on-click="orderStatusCheck"
                    :placement="$store.state.media.isDesktop == true ? 'bottom-end' : ''"
                  >
                    <Button type="primary">
                      <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <template #list>
                      <DropdownMenu>
                        <DropdownItem name="check">跟踪状态</DropdownItem>
                        <DropdownItem disabled divided>DCV全部切换为</DropdownItem>
                        <DropdownItem name="dns" divided>DNS</DropdownItem>
                        <DropdownItem name="http">HTTP</DropdownItem>
                        <DropdownItem name="https">HTTPS</DropdownItem>
                        <Dropdown
                          :placement="
                            $store.state.media.isDesktop == true ? 'left-start' : 'bottom'
                          "
                        >
                          <DropdownItem>
                            子域名邮箱
                            <Icon type="ios-arrow-forward"></Icon>
                          </DropdownItem>
                          <template #list>
                            <DropdownMenu>
                              <DropdownItem name="admin@subdomain.domain.tld"
                                >admin@subdomain.domain.tld</DropdownItem
                              >
                              <DropdownItem name="administrator@subdomain.domain.tld">
                                administrator@subdomain.domain.tld
                              </DropdownItem>
                              <DropdownItem name="administrator@subdomain.domain.tld"
                                >hostmaster@subdomain.domain.tld</DropdownItem
                              >
                              <DropdownItem name="postmaster@subdomain.domain.tld"
                                >postmaster@subdomain.domain.tld</DropdownItem
                              >
                              <DropdownItem name="webmaster@subdomain.domain.tld"
                                >webmaster@subdomain.domain.tld</DropdownItem
                              >
                            </DropdownMenu>
                          </template>
                        </Dropdown>
                        <Dropdown
                          :placement="
                            $store.state.media.isDesktop == true ? 'left-start' : 'bottom'
                          "
                        >
                          <DropdownItem>
                            根域名邮箱
                            <Icon type="ios-arrow-forward"></Icon>
                          </DropdownItem>
                          <template #list>
                            <DropdownMenu>
                              <DropdownItem name="admin@domain.tld"
                                >admin@domain.tld</DropdownItem
                              >
                              <DropdownItem name="administrator@domain.tld"
                                >administrator@domain.tld</DropdownItem
                              >
                              <DropdownItem name="hostmaster@domain.tld"
                                >hostmaster@domain.tld</DropdownItem
                              >
                              <DropdownItem name="hostmaster@domain.tld"
                                >hostmaster@domain.tld</DropdownItem
                              >
                              <DropdownItem name="webmaster@domain.tld"
                                >webmaster@domain.tld</DropdownItem
                              >
                            </DropdownMenu>
                          </template>
                        </Dropdown>
                      </DropdownMenu>
                    </template>
                  </Dropdown>
                </ButtonGroup>
                <Button type="success" :loading="syncLoading" @click="syncStatus"
                  >同步状态</Button
                >
                <span>
                  {{ orderCheckstatus }}
                </span>
              </div>
              <div class="detail-top" v-else>
                <Button
                  type="primary"
                  :loading="syncLoading"
                  @click="orderStatusCheck('check')"
                  >跟踪状态</Button
                >
              </div>
              <div class="detail-content">
                <div class="b-table" v-if="certData.product.ssl_type != 2">
                  <Table
                    :loading="loading"
                    border
                    :disabled-hover="true"
                    :columns="dcv_info"
                    :data="
                      certData.cert.place_order_data
                        ? certData.cert.place_order_data.dcv_info
                        : []
                    "
                  >
                    <template #authType="{ row }">
                      <Select
                        v-model="row.method"
                        style="width: 100%"
                        :transfer="true"
                        @on-change="changeDcv(row)"
                      >
                        <Option value="dns" v-if="row.isip == false">DNS</Option>
                        <Option value="http">HTTP</Option>
                        <Option value="https">HTTPS</Option>
                        <div v-if="row.isip == false">
                          <OptionGroup
                            label="邮箱验证"
                            v-if="row.subdomain && row.subdomain != '*'"
                          >
                            <Option :value="'admin@' + row.domain"
                              >admin@{{ row.domain }}</Option
                            >
                            <Option :value="'administrator@' + row.domain"
                              >administrator@{{ row.domain }}</Option
                            >
                            <Option :value="'hostmaster@' + row.domain"
                              >hostmaster@{{ row.domain }}</Option
                            >
                            <Option :value="'postmaster@' + row.domain"
                              >postmaster@{{ row.domain }}</Option
                            >
                            <Option :value="'webmaster@' + row.domain"
                              >webmaster@{{ row.domain }}</Option
                            >
                            <Option :value="'admin@' + row.topdomain"
                              >admin@{{ row.topdomain }}</Option
                            >
                            <Option :value="'administrator@' + row.topdomain"
                              >administrator@{{ row.topdomain }}</Option
                            >
                            <Option :value="'hostmaster@' + row.topdomain"
                              >hostmaster@{{ row.topdomain }}</Option
                            >
                            <Option :value="'postmaster@' + row.topdomain"
                              >postmaster@{{ row.topdomain }}</Option
                            >
                            <Option :value="'webmaster@' + row.topdomain"
                              >webmaster@{{ row.topdomain }}</Option
                            >
                          </OptionGroup>
                          <OptionGroup label="邮箱验证" v-else>
                            <Option :value="'admin@' + row.topdomain"
                              >admin@{{ row.topdomain }}</Option
                            >
                            <Option :value="'administrator@' + row.topdomain"
                              >administrator@{{ row.topdomain }}</Option
                            >
                            <Option :value="'hostmaster@' + row.topdomain"
                              >hostmaster@{{ row.topdomain }}</Option
                            >
                            <Option :value="'postmaster@' + row.topdomain"
                              >postmaster@{{ row.topdomain }}</Option
                            >
                            <Option :value="'webmaster@' + row.topdomain"
                              >webmaster@{{ row.topdomain }}</Option
                            >
                          </OptionGroup>
                        </div>
                      </Select>
                    </template>

                    <template #dcvExplain="{ row }">
                      <div class="dcv" v-if="row.method == 'dns'">
                        <div class="dcv-content">
                          <span class="copy-title">请添加主机名</span>
                          （<span class="copy" @click="copy(row.dns_host)">复制</span>）
                          <div class="dns-host">
                            <div class="textBox" v-if="row.subdomain">
                              {{ row.dns_host }}
                            </div>
                            <div class="textBox" v-else>{{ row.dns_host }}</div>
                          </div>
                        </div>
                        <div class="dcv-content">
                          <span class="copy-title">类型</span>
                          <div class="dns-host">
                            <div class="textBox">{{ row.dns_type }}</div>
                          </div>
                        </div>
                        <div class="dcv-content dcv-foot">
                          <span class="copy-title">值</span>（<span
                            class="copy"
                            @click="copy(row.dns_value)"
                            >复制</span
                          >）
                          <div class="dns-host">
                            <div class="textBox">{{ row.dns_value }}</div>
                          </div>
                          <span class="copy-title">的DNS记录</span>
                        </div>
                      </div>

                      <div
                        class="dcv"
                        v-else-if="row.method == 'http' || row.method == 'https'"
                      >
                        <div class="dcv-content">
                          <span class="copy-title">请创建</span>（<span
                            class="copy"
                            @click="copy(row[row.method + '_filename'])"
                            >复制</span
                          >）
                          <div class="dns-host">
                            <div class="textBox">{{ row[row.method + "_filename"] }}</div>
                          </div>
                        </div>
                        <div class="dcv-content">
                          <span class="copy-title">内容为</span>（<span
                            class="copy"
                            @click="copy(row[row.method + '_filecontent'])"
                            >复制</span
                          >）
                          <div class="dns-host">
                            <div class="textBox">
                              {{ row[row.method + "_filecontent"] }}
                            </div>
                          </div>
                        </div>
                        <div class="dcv-content dcv-foot">
                          <span class="copy-title">上传到</span>
                          <div class="dns-host">
                            <div class="textBox">
                              {{ row[row.method + "_verifylink"] }}
                            </div>
                          </div>
                          <span class="copy-title">(上传后务必自测是否可访问)</span>
                        </div>
                      </div>
                      <div class="dcv" v-else>
                        <div class="dcv-content dcv-foot">
                          <span class="copy-title">请进入</span>
                          <div class="dns-host">
                            <div class="textBox">{{ row.method }}</div>
                          </div>
                          <span class="copy-title">邮箱收信</span>
                        </div>
                      </div>
                    </template>

                    <template #authStatus="{ row }">
                      <div class="dcv">
                        <div class="dcv-content">
                          <span class="copy-title">{{ row.status }}</span>
                        </div>
                        <div class="dcv-content">
                          <Button @click="checkDcv(row)">检查结果</Button>
                        </div>
                      </div>
                    </template>
                  </Table>
                </div>
                <div class="companyDetailForm" v-else>
                  <Form
                    :model="certData.cert.place_order_data"
                    ref="companyForm"
                    :label-width="100"
                  >
                    <FormItem label="公司名称" prop="organization">
                      <Input
                        v-model="certData.cert.place_order_data.organization"
                        placeholder="请输入公司名称"
                      ></Input>
                    </FormItem>
                    <FormItem label="部门" prop="organization_unit">
                      <Input
                        v-model="certData.cert.place_order_data.organization_unit"
                        placeholder="请输入部门"
                      ></Input>
                    </FormItem>
                    <FormItem label="三证合一号" prop="serial_no">
                      <Input
                        v-model="certData.cert.place_order_data.serial_no"
                        placeholder="请输入三证合一号"
                      ></Input>
                    </FormItem>
                    <FormItem label="成立时间" prop="date_of_incorporation">
                      <DatePicker
                        type="date"
                        v-model="certData.cert.place_order_data.date_of_incorporation"
                        placeholder="请选择成立时间"
                        style="width: 100%"
                      ></DatePicker>
                      <!-- <DatePicker type="date" placeholder="Select date" /> -->
                    </FormItem>
                    <FormItem label="国家" prop="country">
                      <Select
                        v-model="certData.cert.place_order_data.country"
                        placeholder="请选择国家"
                      >
                        <Option value="beijing">New York</Option>
                        <Option value="shanghai">London</Option>
                        <Option value="shenzhen">Sydney</Option>
                        <Option value="CN">中国</Option>
                        <Option value="HK">中国香港</Option>
                        <Option value="TW">中國臺灣</Option>
                        <Option value="US">美国</Option>
                        <Option value="AX">Åland</Option>
                        <Option value="ME">Montenegro</Option>
                        <Option value="RS">Serbia</Option>
                        <Option value="MO">Macau</Option>
                        <Option value="AG">Antigua and Barbuda</Option>
                        <Option value="AF">Afghanistan</Option>
                        <Option value="AZ">Azerbaijan</Option>
                        <Option value="AW">Aruba</Option>
                        <Option value="AE">الامارات</Option>
                        <Option value="AM">Armenia</Option>
                        <Option value="AO">Angola</Option>
                        <Option value="AD">Andorra</Option>
                        <Option value="AI">Anguilla</Option>
                        <Option value="BB">Barbados</Option>
                        <Option value="BM">Bermuda</Option>
                        <Option value="BW">Botswana</Option>
                        <Option value="BS">The Bahamas</Option>
                        <Option value="DZ">Algeria</Option>
                        <Option value="AL">Shqipëri</Option>
                        <Option value="BZ">Belize</Option>
                        <Option value="AR">Argentina</Option>
                        <Option value="BA">Bosna i Hercegovina</Option>
                        <Option value="SB">Solomon Islands</Option>
                        <Option value="BR">Brasil</Option>
                        <Option value="AS">American Samoa</Option>
                        <Option value="BY">Belarus</Option>
                        <Option value="AU">Australia</Option>
                        <Option value="AT">Österreich</Option>
                        <Option value="BG">Bulgaria</Option>
                        <Option value="BH">البحرين</Option>
                        <Option value="CG">Congo</Option>
                        <Option value="BE">België</Option>
                        <Option value="BD">Bangladesh</Option>
                        <Option value="LK">Sri Lanka</Option>
                        <Option value="BO">Bolivia</Option>
                        <Option value="BJ">Benin</Option>
                        <Option value="CC">Cocos (Keeling) Islands</Option>
                        <Option value="MM">Myanmar</Option>
                        <Option value="KY">Cayman Islands</Option>
                        <Option value="BT">Bhutan</Option>
                        <Option value="CM">Cameroon</Option>
                        <Option value="BN">Brunei</Option>
                        <Option value="CO">Colombia</Option>
                        <Option value="CA">Canada</Option>
                        <Option value="TD">Chad</Option>
                        <Option value="BI">Burundi</Option>
                        <Option value="CF">Central African Republic</Option>
                        <Option value="KH">Cambodia</Option>
                        <Option value="CD">Democratic Republic of Congo</Option>
                        <Option value="CV">Cape Verde</Option>
                        <Option value="DK">Danmark</Option>
                        <Option value="EC">Ecuador</Option>
                        <Option value="IE">Ireland</Option>
                        <Option value="EG">مصر</Option>
                        <Option value="GQ">Equatorial Guinea</Option>
                        <Option value="ER">Eritrea</Option>
                        <Option value="ET">Ethiopia</Option>
                        <Option value="CZ">Česká republika</Option>
                        <Option value="FM">Federated States of Micronesia</Option>
                        <Option value="PF">French Polynesia</Option>
                        <Option value="FR">France</Option>
                        <Option value="CL">Chile</Option>
                        <Option value="GA">Gabon</Option>
                        <Option value="GE">Georgia</Option>
                        <Option value="KM">Comoros</Option>
                        <Option value="MP">Northern Mariana Islands</Option>
                        <Option value="GD">Grenada</Option>
                        <Option value="CR">Costa Rica</Option>
                        <Option value="GL">Greenland</Option>
                        <Option value="CU">Cuba</Option>
                        <Option value="CK">Cook Islands</Option>
                        <Option value="GU">Guam</Option>
                        <Option value="GT">Guatemala</Option>
                        <Option value="GY">Guyana</Option>
                        <Option value="DJ">Djibouti</Option>
                        <Option value="GR">Ellás</Option>
                        <Option value="DM">Dominica</Option>
                        <Option value="DO">Dominican Republic</Option>
                        <Option value="EE">Eesti</Option>
                        <Option value="SV">El Salvador</Option>
                        <Option value="GF">Guyane Française</Option>
                        <Option value="FJ">Fiji</Option>
                        <Option value="FI">Suomi</Option>
                        <Option value="FK">Falkland Islands</Option>
                        <Option value="FO">Føroyar</Option>
                        <Option value="GM">The Gambia</Option>
                        <Option value="GH">Ghana</Option>
                        <Option value="GI">Gibraltar</Option>
                        <Option value="DE">Deutschland</Option>
                        <Option value="GP">Guadeloupe</Option>
                        <Option value="GN">Guinea</Option>
                        <Option value="HN">Honduras</Option>
                        <Option value="IS">Ísland</Option>
                        <Option value="HT">Haiti</Option>
                        <Option value="HU">Magyarország</Option>
                        <Option value="IO">British Indian Ocean Territory</Option>
                        <Option value="IT">Italia</Option>
                        <Option value="CI">Côte d´Ivoire</Option>
                        <Option value="JM">Jamaica</Option>
                        <Option value="KP">North Korea</Option>
                        <Option value="JO">الأردن</Option>
                        <Option value="KZ">Kazakhstan</Option>
                        <Option value="LB">لبنان</Option>
                        <Option value="KR">한국</Option>
                        <Option value="KW">الكويت</Option>
                        <Option value="LA">Laos</Option>
                        <Option value="LR">Liberia</Option>
                        <Option value="LS">Lesotho</Option>
                        <Option value="MG">Madagascar</Option>
                        <Option value="LY">Libya</Option>
                        <Option value="HR">Hrvatska</Option>
                        <Option value="MW">Malawi</Option>
                        <Option value="ML">Mali</Option>
                        <Option value="ID">Republik Indonesia</Option>
                        <Option value="MA">المغرب</Option>
                        <Option value="MT">Malta</Option>
                        <Option value="MU">Mauritius</Option>
                        <Option value="MY">Malaysia</Option>
                        <Option value="NC">New Caledonia</Option>
                        <Option value="VU">Vanuatu</Option>
                        <Option value="NL">Nederland</Option>
                        <Option value="NP">Nepal</Option>
                        <Option value="SR">Suriname</Option>
                        <Option value="NZ">New Zealand</Option>
                        <Option value="NG">Nigeria</Option>
                        <Option value="PN">Pitcairn Islands</Option>
                        <Option value="IR">Iran</Option>
                        <Option value="IN">India</Option>
                        <Option value="IL">Israel</Option>
                        <Option value="IQ">Iraq</Option>
                        <Option value="JP">日本</Option>
                        <Option value="KE">Kenya</Option>
                        <Option value="KI">Kiribati</Option>
                        <Option value="CX">Christmas Island</Option>
                        <Option value="KG">Kyrgyzstan</Option>
                        <Option value="LT">Lietuva</Option>
                        <Option value="SK">Slovenská</Option>
                        <Option value="LI">Liechtenstein</Option>
                        <Option value="LV">Latvija</Option>
                        <Option value="LU">Luxembourg</Option>
                        <Option value="MD">Moldova</Option>
                        <Option value="MN">Mongolia</Option>
                        <Option value="MQ">Martinique</Option>
                        <Option value="YT">Mayotte</Option>
                        <Option value="MS">Montserrat</Option>
                        <Option value="MK">Macedonia</Option>
                        <Option value="MC">Monaco</Option>
                        <Option value="MV">Maldives</Option>
                        <Option value="MR">Mauritania</Option>
                        <Option value="NF">Norfolk Island</Option>
                        <Option value="OM">عمان</Option>
                        <Option value="MX">México</Option>
                        <Option value="PK">Pakistan</Option>
                        <Option value="MZ">Mozambique</Option>
                        <Option value="PA">Panamá</Option>
                        <Option value="NU">Niue</Option>
                        <Option value="PG">Papua New Guinea</Option>
                        <Option value="NE">Niger</Option>
                        <Option value="NO">Norge</Option>
                        <Option value="QA">قطر</Option>
                        <Option value="NR">Nauru</Option>
                        <Option value="PH">Philippines</Option>
                        <Option value="NI">Nicaragua</Option>
                        <Option value="PY">Paraguay</Option>
                        <Option value="SA">السعودية</Option>
                        <Option value="PE">Peru</Option>
                        <Option value="PL">Polska</Option>
                        <Option value="XX">Disputed Territory</Option>
                        <Option value="PW">Palau</Option>
                        <Option value="MH">Marshall Islands</Option>
                        <Option value="RE">La Réunion</Option>
                        <Option value="RU">Russia</Option>
                        <Option value="PR">Puerto Rico</Option>
                        <Option value="PM">Saint Pierre and Miquelon</Option>
                        <Option value="XS">Spratly Islands</Option>
                        <Option value="PT">Portugal</Option>
                        <Option value="GW">Guinea-Bissau</Option>
                        <Option value="RO">România</Option>
                        <Option value="RW">Rwanda</Option>
                        <Option value="KN">Saint Kitts and Nevis</Option>
                        <Option value="SN">Sénégal</Option>
                        <Option value="SM">San Marino</Option>
                        <Option value="LC">St. Lucia</Option>
                        <Option value="GS"
                          >South Georgia and the South Sandwich Islands</Option
                        >
                        <Option value="TK">Tokelau</Option>
                        <Option value="SH"
                          >St Helena Ascension and Tristan da Cunha</Option
                        >
                        <Option value="TN">Tunisia</Option>
                        <Option value="SL">Sierra Leone</Option>
                        <Option value="ES">España</Option>
                        <Option value="SC">Seychelles</Option>
                        <Option value="SI">Slovenija</Option>
                        <Option value="SO">Somalia</Option>
                        <Option value="CH">Schweiz</Option>
                        <Option value="TJ">Tajikistan</Option>
                        <Option value="SD">Sudan</Option>
                        <Option value="SY">Syria</Option>
                        <Option value="TT">Trinidad and Tobago</Option>
                        <Option value="TC">Turks and Caicos Islands</Option>
                        <Option value="TO">Tonga</Option>
                        <Option value="ZA">South Africa</Option>
                        <Option value="TG">Togo</Option>
                        <Option value="TR">Turkey</Option>
                        <Option value="SG">Singapore</Option>
                        <Option value="SE">Sverige</Option>
                        <Option value="TZ">Tanzania</Option>
                        <Option value="TH">Thailand</Option>
                        <Option value="GB">United Kingdom</Option>
                        <Option value="US">United States</Option>
                        <Option value="UY">Uruguay</Option>
                        <Option value="VG">British Virgin Islands</Option>
                        <Option value="VI">US Virgin Islands</Option>
                        <Option value="NA">Namibia</Option>
                        <Option value="WF">Wallis and Futuna</Option>
                        <Option value="UA">Ukrayina</Option>
                        <Option value="VE">Venezuela</Option>
                        <Option value="VA">Città del Vaticano</Option>
                        <Option value="XE">Iraq-Saudi Arabia Neutral Zone</Option>
                        <Option value="ZM">Zambia</Option>
                        <Option value="WS">Samoa</Option>
                        <Option value="ST">Sao Tome and Principe</Option>
                        <Option value="VC">Saint Vincent and the Grenadines</Option>
                        <Option value="TL">Timor-Leste</Option>
                        <Option value="TV">Tuvalu</Option>
                        <Option value="TM">Turkmenistan</Option>
                        <Option value="UG">Uganda</Option>
                        <Option value="BF">Burkina Faso</Option>
                        <Option value="UZ">Uzbekistan</Option>
                        <Option value="VN">Vietnam</Option>
                        <Option value="SZ">Swaziland</Option>
                        <Option value="EH">Western Sahara</Option>
                        <Option value="XD">United Nations Neutral Zone</Option>
                        <Option value="YE">Yemen</Option>
                        <Option value="ZW">Zimbabwe</Option>
                        <Option value="CY">Cyprus</Option>
                        <Option value="UM">United States Minor Outlying Islands</Option>
                        <Option value="HM">Heard Island and McDonald Islands</Option>
                        <Option value="SJ">Svalbard and Jan Mayen</Option>
                        <Option value="AQ">Antarctica</Option>
                        <Option value="TF">French Southern Territories</Option>
                        <Option value="PS">Palestine</Option>
                        <Option value="BV">Bouvet Island</Option>
                        <Option value="MF">Saint-Martin</Option>
                        <Option value="BL">Saint-Barthélemy</Option>
                        <Option value="BQ">Bonaire Saint Eustatius and Saba</Option>
                        <Option value="SS">The Republic of South Sudan</Option>
                        <Option value="CW">Curaçao</Option>
                        <Option value="SX">Sint Maarten</Option>
                        <Option value="DE">德国</Option>
                      </Select>
                    </FormItem>
                    <FormItem label="省份" prop="state">
                      <Input
                        v-model="certData.cert.place_order_data.state"
                        placeholder="请输入省份"
                      ></Input>
                    </FormItem>
                    <FormItem label="城市" prop="city">
                      <Input
                        v-model="certData.cert.place_order_data.city"
                        placeholder="请输入城市"
                      ></Input>
                    </FormItem>
                    <FormItem label="注册地址" prop="registered_address_line1">
                      <Input
                        v-model="certData.cert.place_order_data.registered_address_line1"
                        placeholder="请输入注册地址"
                      ></Input>
                    </FormItem>
                    <FormItem label="邮政编号" prop="postal_code">
                      <Input
                        v-model="certData.cert.place_order_data.postal_code"
                        placeholder="请输入邮政编号"
                      ></Input>
                    </FormItem>
                    <FormItem label="企业电话" prop="organization_phone">
                      <Input
                        v-model="certData.cert.place_order_data.organization_phone"
                        placeholder="请输入企业电话"
                      ></Input>
                    </FormItem>
                    <FormItem label="联系人" prop="contact_name">
                      <Input
                        v-model="certData.cert.place_order_data.contact_name"
                        placeholder="请输入联系人"
                      ></Input>
                    </FormItem>
                    <FormItem label="职位" prop="contact_title">
                      <Input
                        v-model="certData.cert.place_order_data.contact_title"
                        placeholder="请输入职位"
                      ></Input>
                    </FormItem>
                    <FormItem label="联系人电话" prop="contact_phone">
                      <Input
                        v-model="certData.cert.place_order_data.contact_phone"
                        placeholder="请输入联系人电话"
                      ></Input>
                    </FormItem>
                  </Form>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="签发信息" name="4">
            <div class="processDetail">
              <div class="detail-title">签发信息</div>
              <div class="detail-content" v-if="certData.cert.status != 2">
                <span
                  >证书未签发，请查看<span @click="tabsClick('3')">审核状态</span></span
                >
              </div>
              <div class="detail-content" v-if="certData.cert.status == 2">
                <div class="toolBox">
                  证书类型：
                  <RadioGroup
                    type="button"
                    button-style="solid"
                    v-model="certSignData.key_type"
                  >
                    <Radio label="0" v-if="certData.cert.cert_ificate">RSA</Radio>
                    <Radio label="3" v-if="certData.cert.ec_cert_ificate">EC</Radio>
                  </RadioGroup>
                </div>
                <div class="toolBox">
                  <div class="item-title">下载、转换</div>
                  <div class="item-ctrlBtn">
                    <Button type="default" @click="exoprtCert('cert')">签发证书</Button>
                    <Button type="default" @click="exoprtCert('issuer')">中间证书</Button>
                    <Button type="default" @click="exoprtCert('both')"
                      >签发证书+中间证书</Button
                    >
                    <Button type="default" @click="showModal"
                      >导出IlS(pfx格式)证书</Button
                    >
                  </div>
                </div>
                <div class="toolBox">
                  <div class="item-title">签发证书</div>
                  <div class="item-ctrlBtn">
                    <pre class="buildPfx" v-if="certSignData.key_type == 0">{{
                      certData.cert.cert_ificate
                    }}</pre>
                    <pre class="buildPfx" v-if="certSignData.key_type == 3">{{
                      certData.cert.ec_cert_ificate
                    }}</pre>
                  </div>
                </div>
                <div class="toolBox">
                  <div class="item-title">中间证书</div>
                  <div class="item-ctrlBtn">
                    <!-- <div class="buildPfx" v-html='certData.cert.cert_ificate'></div> -->
                    <pre class="buildPfx" v-if="certSignData.key_type == 0">{{
                      certData.cert.ca_cert_ificate
                    }}</pre>
                    <pre class="buildPfx" v-if="certSignData.key_type == 3">{{
                      certData.cert.ec_ca_cert_ificate
                    }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <Modal
            v-model="showExoprtCertModal"
            :closable="true"
            :mask-closable="false"
            :loading="true"
            title="导出IIS证书"
          >
            <Form
              class="form"
              ref="chargeForm"
              :model="chargeData"
              :rules="ruleValidate"
              label-width="100"
              :label-position="labelPosition"
            >
              <Row :gutter="24" type="flex">
                <Col span="24">
                  <FormItem label="PFX密码" prop="amount">
                    <Input type="text" v-model="pfxPwd" size="large" />
                  </FormItem>
                  <FormItem
                    label="证书私钥"
                    prop="key"
                    v-if="
                      (certSignData.key_type == 0 && certData.cert.is_key == 0) ||
                      (certSignData.key_type == 3 && certData.cert.is_key_ec == 0)
                    "
                  >
                    <div class="upload">
                      <Button
                        type="primary"
                        @click="handleClick('cert')"
                        icon="md-cloud-upload"
                        >上传文件</Button
                      >
                      <div class="fileName">{{ csrFileName }}</div>
                      <input
                        type="file"
                        ref="fileInput"
                        @change="fileChange"
                        style="display: none"
                      />
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <template #footer>
              <div class="footerBtn">
                <Button type="primary" size="default" @click="exportIISCert('zip')"
                  >转换PFX格式, 并打包 ZIP</Button
                >
                <Button type="success" size="default" @click="exportIISCert('')"
                  >转换PFX格式
                </Button>
                <Button type="primary" size="default" @click="showExoprtCertModal = false"
                  >取消</Button
                >
              </div>
            </template>
          </Modal>
          <Modal
            v-model="showDeleteDomainModal"
            :closable="true"
            :mask-closable="false"
            :loading="true"
            title="域名删除"
          >
            <div class="delDomain">
              <div
                class="domainList"
                v-for="(ite, domInex) in certData.cert.place_order_data.san"
                :key="domInex"
              >
                <div class="value">
                  {{ ite }}
                </div>
                <Icon
                  type="md-close"
                  size="32"
                  class="delIcon"
                  @click="delDomain(ite, domInex)"
                />
              </div>
            </div>
            <template #footer>
              <div class="footerBtn">
                <Button type="primary" size="large" @click="showDeleteDomainModal = false"
                  >取消</Button
                >
              </div>
            </template>
          </Modal>
          <companyDetailDrawer
            @refresh="reFreshCompanyList"
            ref="companyDetailRef"
          ></companyDetailDrawer>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  certApplyApi,
  checkDcvApi,
  changeDcvApi,
  noticeCACheckApi,
  companyApi,
  userInfoApi,
} from "@/api/personalCenter";
import {
  certDetailApi,
  certExportApi,
  iisCertDownApi,
  domainsDeleteApi,
  certSREsueApi,
} from "@/api/cert";
import {
  applyRefundApi,
  orderStatusCheckApi,
  syncStatusApi,
  blukChangeDCVApi,
} from "@/api/order";

import {
  ref,
  reactive,
  watchEffect,
  onMounted,
  defineProps,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { Message, Modal, Copy } from "view-ui-plus";
import CompanyDetailDrawer from "@/components/companyDetailDrawer";

const { proxy } = getCurrentInstance(); // 获取组件实例

let router = useRouter();
let route = useRoute();
let activeTabs = ref(1);
let showExoprtCertModal = ref(false);
let showDeleteDomainModal = ref(false);
let companyDetailRef = ref(null);
let pfxPwd = ref("");
const order_id = route.query.id;
const certData = reactive({
  add_time: "",
  pay_time: "",
  product: {
    store_name: "",
    support_san: 0,
    default_protect_domain: 0,
    max_domain: 0,
    ssl_free: 0,
  },
  placeholder: { common_effect: "" },
  cert: {
    ca_id: "",
    add_time: "",
    place_order_data: {
      dcv_info: [],
    },
  },
  refund_reason_time: "",
});
// 申请资料表单
let formItem = reactive({
  order_id: "",
  common_name: "",
  san: "",
  email: "",
  cert_type: "",
  csr_type: "1",
  csr: "",
  company_id: "",
});
// 申请资料表单验证
const ruleItem = reactive({
  common_name: [{ required: true, message: "主域名不能为空", trigger: "blur" }],
  email: [
    { required: true, message: "联系人 Email 不能为空", trigger: "blur" },
    { type: "email", message: "请输入正确的 Email", trigger: "blur" },
  ],
  company_id: [
    { required: true, type: "number", message: "请选择公司", trigger: "change" },
  ],
});
const dcv_info = reactive([
  {
    title: "FQDN",
    key: "domain",
    minWidth: 150,
  },
  {
    title: "验证类型",
    slot: "authType",
    minWidth: 80,
  },
  {
    title: "说明",
    slot: "dcvExplain",
    minWidth: 600,
  },
  {
    title: "验证状态",
    slot: "authStatus",
    minWidth: 100,
  },
]);
const buildCert = ref("");
let applyLoading = ref(false);
let loading = ref(false);
let noticeLoading = ref(false);
let syncLoading = ref(false);
let companyList = reactive([]);
let certSignData = reactive({
  cert_id: "",
  key_type: "",
  type: "",
});
let reissueLoading = ref(false);

let orderCheckstatus = ref(
  "请在全部添加完成再验证，否则因为DNS TTL等原因会导致下次验证需要等待更久"
);
let csrFileName = ref("请上传证书秘钥");
let fileInput = ref(null);

// ----- --接口请求----------
const certDetail = (data) => {
  certDetailApi(data).then((res) => {
    if (res.status == 200) {
      proxy.$translate.execute();

      Object.assign(certData, res.data.info);
      if (certData.cert == null) {
        certData.cert = {
          ca_id: "",
          add_time: "",
        };
      } else {
        Object.assign(formItem, certData.cert.place_order_data);
        formItem.csr_type = formItem.csr_type.toString();
        certSignData.key_type = certData.cert.key_type.toString();
        certSignData.cert_id = certData.cert.id;
      }
      if (route.query.all_domain && certData.status_current == 4) {
        formItem.all_domain = route.query.all_domain;
        // console.log(formItem.all_domain);
        activeTabs.value = "2";
      } else {
        activeTabs.value =
          certData.status_current != 0 ? certData.status_current.toString() : "1";
      }
    }
  });
};
function getCompanyList(data) {
  companyApi(data).then((res) => {
    if (res.status === 200) {
      //   console.log(res.data.list);
      companyList.length = 0; // 清空数组
      companyList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
// -------操作按钮----------

const tabsClick = (e) => {
  // console.log(e);
  activeTabs.value = e;
};
// 申请退款
const applyRefund = (id) => {
  Modal.confirm({
    title: "退款确认",
    content:
      "您的订单将退款, 已签发的证书将在1到7日内吊销不能使用,资金将于7日内到达您的余额账户, 是否继续？",
    loading: true,
    onOk() {
      applyRefundApi({ order_id: id })
        .then((res) => {
          Message.success("退款成功");
          setTimeout(() => {
            Modal.remove();
            certDetail({ id: id });
          }, 200);
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};
// 添加公司提示
const showCompanyDrwaer = () => {
  companyDetailRef.value.addCompany();
};

// 删除域名
const delDomain = (domain, index) => {
  Modal.confirm({
    title: "删除确认",
    content: `您将删除域名：“${domain}”, 是否继续？`,
    loading: true,
    onOk() {
      domainsDeleteApi({ order_id: order_id, domain_name: domain })
        .then((res) => {
          Message.success("删除成功");
          setTimeout(() => {
            Modal.remove();
            certData.cert.place_order_data.san.splice(index, 1);
            certDetail({ id: order_id });
            showDeleteDomainModal.value = false;
            activeTabs.value = "2";
          }, 200);
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};
// 刷新公司列表
const reFreshCompanyList = () => {
  getCompanyList();
};
// 去申请 按钮
const apply = () => {
  activeTabs.value = "2";
};
// 提交申请
const applyCert = () => {
  proxy.$refs.certDataForm.validate((valid) => {
    if (valid) {
      certApplyApi(formItem)
        .then((res) => {
          applyLoading.value = true;
          if (res.status == 200) {
            Message.success("申请成功");
            certDetail({ id: order_id });
            activeTabs.value = 3;
            applyLoading.value = false;
          }
        })
        .catch((err) => {
          Message.error(err.msg);
          applyLoading.value = false;
        });
    } else {
      Message.error(res.msg);
    }
  });
};
// 重签申请接口请求
const certSREsue = (data) => {
  reissueLoading.value = true;
  certSREsueApi(data)
    .then((res) => {
      // console.log(res);
      if (res.status == 200) {
        Message.success("申请成功");
        certDetail({ id: order_id });
        activeTabs.value = 3;
        reissueLoading.value = false;
      } else if (res.status == 300) {
        Modal.confirm({
          title: "订单超额提示",
          content: res.msg,
          loading: true,
          onOk() {
            Modal.remove();
            router.push({
              path: "/sanPayment",
              query: {
                order_id: order_id,
                san_order_id: res.data.order_id,
                all_domain: formItem.all_domain,
              },
            });
          },
          onCancel() {
            Modal.remove();
            reissueLoading.value = false;
          },
        });
      }
    })
    .catch((err) => {
      Message.error(err.msg);
      reissueLoading.value = false;
    });
};
// 重签申请按钮
const certSREsueSubmit = () => {
  proxy.$refs.certDataForm.validate((valid) => {
    if (valid) {
      let forms = {
        order_id: order_id,
        common_name: formItem.all_domain,
        email: formItem.email,
        cert_type: formItem.cert_type,
        csr_type: Number(formItem.csr_type),
        csr: formItem.csr,
        hash_algorithms: formItem.hash_algorithms,
        keysize: formItem.keysize.toString(),
        company_id: formItem.company_id,
      };
      certSREsue(forms);
    } else {
      Message.error(res.msg);
    }
  });
};
// 检查Dcv
const checkDcv = (row) => {
  let data = {
    order_id: order_id,
    domain: row.domain,
    type: row.method,
  };
  checkDcvApi(data)
    .then((res) => {
      Message.success("验证成功");
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 切换验证方式
const changeDcv = (row) => {
  console.log(row);
  let data = {
    order_id: order_id,
    domain: row.domain,
    type: row.method,
  };
  loading.value = true;
  changeDcvApi(data)
    .then((res) => {
      loading.value = false;
      Message.success("切换成功");
      certDetail({ id: order_id });
    })
    .catch((err) => {
      loading.value = false;
      Message.error(err.msg);
    });
};
// 同步状态
const syncStatus = () => {
  syncStatusApi({ order_id: order_id })
    .then((res) => {
      syncLoading.value = false;
      certDetail({ id: order_id });
      Message.success(res.msg || "已同步");
    })
    .catch((err) => {
      syncLoading.value = false;
      Message.error(err.msg);
    });
};
// 重新验证
const noticeCACheck = () => {
  noticeCACheckApi({ id: order_id })
    .then((res) => {
      syncLoading.value = false;
      Message.success(res.msg || "已通知CA检查");
    })
    .catch((err) => {
      syncLoading.value = false;
      Message.error(err.msg);
    });
};
// 状态跟踪
const orderStatusCheck = (e) => {
  if (e == "check") {
    orderStatusCheckApi({ order_id: order_id })
      .then((res) => {
        if (res.status == 200) {
          Message.success("查询成功，即将跳转");
          setTimeout(() => {
            // 创建 <a> 元素
            Modal.confirm({
              title: "跳转确认",
              content: "即将跳转至查询结果页面, 是否继续？",
              onOk() {
                window.open(res.data.url, "_blank");
              },
              onCancel() {
                Modal.remove();
              },
            });
            // window.location.href = res.data.url;
          }, 1000);
        }
      })
      .catch((err) => {
        Message.error(err.msg);
      });
  } else {
    // 处理批量切换验证方式
    let email_type = 0;
    // 处理验证方式类型
    if (e.includes("@domain")) {
      email_type = 2;
    } else if (e.includes("@subdomain")) {
      email_type = 1;
    } else {
      email_type = 0;
    }
    let data = {
      order_id: order_id,
      type: e,
      email_type: email_type,
    };
    orderCheckstatus.value = "批量切换验证方式中！ 请勿重复切换！";
    loading.value = true;
    blukChangeDCVApi(data)
      .then((res) => {
        loading.value = false;
        Message.success("切换成功");
        certDetail({ id: order_id });
        orderCheckstatus.value =
          "请在全部添加完成再验证，否则因为DNS TTL等原因会导致下次验证需要等待更久";
      })
      .catch((err) => {
        loading.value = false;
        Message.error(err.msg);
        orderCheckstatus.value =
          "请在全部添加完成再验证，否则因为DNS TTL等原因会导致下次验证需要等待更久";
      });
  }
};
// 立刻付款按钮
const goPay = (id) => {
  router.push({
    path: "/payment",
    query: {
      order_id: id,
    },
  });
};

// 导出证书
const exoprtCert = (type) => {
  certSignData.type = type;
  certExportApi(certSignData)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://api.rjxssl.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
// 展示弹窗
const showModal = () => {
  userInfoApi().then((res) => {
    if (res.status == 200) {
      pfxPwd.value = res.data.info.pfx_password;
    }
  });
  showExoprtCertModal.value = true;
  certSignData.key = "";
  csrFileName.value = "请上传证书秘钥";
};
// 上传文件
const handleClick = () => {
  fileInput.value.click(); // 触发隐藏的文件输入框
};
const fileChange = (event) => {
  certSignData.key = event.target.files[0];
  csrFileName.value = event.target.files[0].name;
};
// 导出IIS证书
const exportIISCert = (type) => {
  let data = {
    id: certData.cert.id,
    key_type: certSignData.key_type,
    action: type,
    password: pfxPwd.value,
  };
  if (certData.cert.place_order_data.csr_type == "0" && certData.cert.is_key == 0) {
    data.key = certSignData.key;
  }
  if (certData.cert.place_order_data.csr_type == "3" && certData.cert.is_key_ec == 0) {
    data.key = certSignData.key;
  }
  iisCertDownApi(data)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://api.rjxssl.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
const copy = (v) => {
  Copy({
    text: v,
  });
};
onMounted(() => {
  certDetail({ id: order_id });

  getCompanyList();
});
defineExpose({
  certDataForm: proxy.$refs.certDataForm,
});
</script>

<style lang="less" scoped>
.certDetail-Pc {
  padding: 25px 5% 40px;

  .routerBox {
    margin-bottom: 15px;
  }

  .process {
    background-color: #fff;
    padding: 20px 40px;
    margin-bottom: 50px;

    .section-hd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }
  }

  .processDetail {
    background-color: #fff;

    .detail-title {
      padding: 26px 15px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .detail-top {
      padding: 26px 15px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      span {
        margin: 0 10px;
      }
    }

    .detail-content {
      padding: 37px 15px;
      font-size: 16px;
      border-top: 1px dashed #cecece;
      border-bottom: 1px dashed #cecece;

      .content-item {
        display: flex;
        flex-direction: row;
        color: #000;
        margin-bottom: 15px;
        align-items: center;
        .item-label {
          min-width: 70px;
        }

        .order_price {
          color: red;
        }

        // font-family: SourceHanSansSC-regular;
      }

      .b-table {
        .dcv-content {
          margin-top: 10px;

          .copy-title {
            font-weight: 700;
          }

          .copy {
            color: #337ab7;
            cursor: pointer;
          }

          .dns-host {
            .textBox {
              // margin: 14px 0;
              padding: 14px 10px;
              border-radius: 8px;
              background-color: #f5f5f5;
              border: 1px solid #cecece;
            }
          }
        }
      }

      .toolBox {
        color: #000;
        margin-bottom: 15px;

        .item-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .item-ctrlBtn {
          button {
            margin-right: 5px;
          }
        }

        .buildPfx {
          width: 100%;
          height: 450px;
          overflow: scroll;
          background-color: #f5f5f5;
          border: 1px solid #cecece;
          padding: 14px 10px;
          border-radius: 5px;
        }
      }
    }

    .detail-footer {
      padding: 35px 40px;
      text-align: right;

      button {
        height: 40px;
        width: 120px;
      }
    }
  }
  .upload {
    margin-bottom: 20px;
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .fileName {
      margin-left: 30px;
    }
  }
  .apply {
    margin-top: 15px;
    .ivu-select {
      margin-right: 25px;
      margin-bottom: 15px;
    }
    .applyBtn {
      margin-top: 15px;
    }
  }
  :deep(.ivu-tabs-nav) {
    font-size: 16px;
  }
  .btnBox {
    margin-top: 20px;
  }
  :deep(.ivu-select-dropdown) {
    width: auto;
  }
  :deep(.ivu-dropdown-item-disabled) {
    color: #000;
  }
}

.certDetail-notPc {
  padding: 25px 5% 20px;
  .apply {
    display: flex;
    margin-top: 15px;
    line-height: 32px;
  }
  .routerBox {
    margin-bottom: 15px;
  }
  :deep(.ivu-tabs-nav-scrollable) {
    padding: 0 10px;
  }
  :deep(.ivu-tabs-nav-prev) {
    display: none !important;
  }
  :deep(.ivu-tabs-nav-next) {
    display: none !important;
  }

  :deep(.ivu-tabs-nav-scroll) {
    overflow: scroll !important;
  }
  :deep(.ivu-tabs-nav-scroll::-webkit-scrollbar) {
    height: 0;
  }
  :deep(.ivu-select-dropdown) {
    width: auto;
  }
  :deep(.ivu-dropdown-item-disabled) {
    color: #000;
  }
  .process {
    background-color: #fff;
    padding: 20px 20px;
    margin-bottom: 25px;

    .section-hd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }
  }
  .processDetail {
    background-color: #fff;

    .detail-title {
      padding: 26px 15px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .detail-top {
      padding: 26px 15px;
      display: flex;
      flex-direction: column;
      :deep(.ivu-btn-success) {
        width: 136px;
        margin: 10px 0;
      }
      span {
        margin: 0 10px;
      }
    }

    .detail-content {
      padding: 10px 5px;

      border-top: 1px dashed #cecece;
      border-bottom: 1px dashed #cecece;

      .content-item {
        display: flex;
        flex-direction: row;
        color: #000;
        margin-bottom: 15px;

        .item-label {
          min-width: 70px;
        }

        .order_price {
          color: red;
        }

        // font-family: SourceHanSansSC-regular;
      }

      .b-table {
        .dcv-content {
          margin-top: 10px;

          .copy-title {
            font-weight: 700;
          }

          .copy {
            color: #337ab7;
            cursor: pointer;
          }

          .dns-host {
            .textBox {
              // margin: 14px 0;
              padding: 14px 10px;
              border-radius: 8px;
              background-color: #f5f5f5;
              border: 1px solid #cecece;
            }
          }
        }
      }

      .toolBox {
        color: #000;
        margin-bottom: 15px;

        .item-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .item-ctrlBtn {
          button {
            margin-right: 5px;
          }
        }

        .buildPfx {
          width: 100%;
          height: 450px;
          overflow: scroll;
          background-color: #f5f5f5;
          border: 1px solid #cecece;
          padding: 14px 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
.domainList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
  .value {
    margin-right: 30px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    min-width: 160px;
  }
  .delIcon {
    cursor: pointer;
    color: red;
  }
}
</style>
